import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`

  main {
    margin-top: 80px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .wrapper {
    max-width: 1920px;
    margin: 0 20px;
    display: grid;
    grid-gap: 10px;
  }


  /* no grid support? */

  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .wrapper {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-auto-rows: minmax(150px, auto);
  }

  .panel {
    /* needed for the flex layout*/
    margin-left: 5px;
    margin-right: 5px;
    flex: 1 1 200px;
  }

  .header,
  .footer {
    margin-left: 5px;
    margin-right: 5px;
    flex: 0 1 100%;
    grid-column: 1 / -1;
  }

  .wrapper > * {
    padding: 20px;
    margin-bottom: 10px;
  }


  /* We need to set the margin used on flex items to 0 as we have gaps in grid.  */

  @supports (display: grid) {
    .wrapper > * {
      margin: 0;
    }
  }

`;