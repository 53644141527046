import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/select/select';
import '@shoelace-style/shoelace/dist/components/option/option';

@customElement('date-selector')
export class DateSelector extends LitElement {

  @state()
  year: number;

  @state()
  month: number;

  @state()
  day!: number;

  @state()
  days: any[];

  constructor() {
    super();
    this.year  = new Date().getFullYear();
    this.month = new Date().getMonth() + 1; // Enero es 0, así que añadimos 1
    this.days  = this.calculateDays(this.year, this.month);
  }

  // Este método se llama cada vez que cambian el año o el mes
  updated(changedProperties: { has: (arg0: string) => any; }) {
    if (changedProperties.has('year') || changedProperties.has('month')) {
      this.days = this.calculateDays(this.year, this.month);
    }
  }

  // Calcula los días disponibles según el año y el mes
  calculateDays(year: number, month: number) {
    const daysInMonth = new Date(year, month, 0).getDate(); // Calcula el número de días en el mes
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }

  render() {
    return html`
      <label for="year">Año:</label>
      <sl-select id="year" @sl-change="${this.updateYear}" value="${this.year}">
        ${this.renderYears()}
      </sl-select>

      <label for="month">Mes:</label>
      <sl-select id="month" @sl-change="${this.updateMonth}" value="${this.month}">
        ${this.renderMonths()}
      </sl-select>

      <label for="day">Día:</label>
      <sl-select id="day" @sl-change="${this.updateDay}">
        ${this.days.map(day => html`<sl-option value="${day}">${day}</sl-option>`)}
      </sl-select>
    `;
  }

  // Renderiza las opciones para el selector de años
  renderYears() {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 2 }, (_, i) => html`
      <sl-option value="${currentYear + i}" ?selected="${this.year === currentYear + i}">
        ${currentYear + i}
      </sl-option>
    `);
  }

  // Renderiza las opciones para el selector de meses
  renderMonths() {
    return Array.from({ length: 12 }, (_, i) => html`
      <sl-option value="${i + 1}" ?selected="${this.month === i + 1}">
        ${this.firstLetterToUpperCase(new Date(0, i).toLocaleString('default', { month: 'long' }))}
      </sl-option>
    `);
  }

  // Actualiza el año seleccionado
  updateYear(e: { target: { value: string; }; }) {
    this.year = parseInt(e.target.value);
    this.triggerCustomEvent();
  }

  // Actualiza el mes seleccionado
  updateMonth(e: { target: { value: string; }; }) {
    this.month = parseInt(e.target.value);
    this.triggerCustomEvent();
  }

  // Actualiza el día seleccionado
  updateDay(e: { target: { value: string; }; }) {
    this.day = parseInt(e.target.value);
    this.triggerCustomEvent();
  }

  triggerCustomEvent() {
    // Dispatch an event with selected date
    this.dispatchEvent(new CustomEvent('date-selected', {
      detail: { dateSelected: `${this.year}-${this.month<10?'0'+this.month:this.month}-${this.day<10?'0'+this.day:this.day}` },
      bubbles: true,
      composed: true
    }));
  }

  firstLetterToUpperCase(word: string) {
    return word.charAt(0).toUpperCase()+word.slice(1);
  }
}

